import type {
  BackgroundColor,
  Color,
  ColorGroup,
  TextColor,
} from '@lib/colors/colors'

type NavBarColorConfig = {
  backgroundColor: BackgroundColor | `bg-${Color}` | `bg-${Color}/[${number}]`
  borderColor: string
  iconColor: TextColor
}

export function getColorConfigFromColorGroup(
  colorGroup: ColorGroup,
): NavBarColorConfig {
  switch (colorGroup) {
    case 'lightGrey':
      return {
        backgroundColor: 'bg-neutral100',
        borderColor: 'transparent',
        iconColor: 'text-black',
      }
    case 'warm':
      return {
        backgroundColor: 'bg-transparent',
        borderColor: 'white',
        iconColor: 'text-warm900',
      }
    case 'black':
      return {
        backgroundColor: 'bg-black',
        borderColor: 'transparent',
        iconColor: 'text-white',
      }
    default:
      return {
        backgroundColor: 'bg-white/[0.24]',
        borderColor: 'neutral100',
        iconColor: 'text-black',
      }
  }
}
