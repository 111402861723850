import decodeJwt from '@modules/auth/jwt/decodeJwt'
import getJwtCookie from '@modules/auth/jwt/getJwtCookie'
import type { UserJwtPayload } from '@modules/auth/jwt/types'
import type { Role } from '@prisma/client'
import { useEffect, useState } from 'react'

export function useUserRoles() {
  const jwt = getJwtCookie()
  const decodedJwt = jwt ? decodeJwt<UserJwtPayload>(jwt) : undefined
  const decodedRoles = decodedJwt ? decodedJwt.roles : []
  const roles = decodedRoles.map((role) => role.type)
  const [userRoles, setUserRoles] = useState<Role[]>(roles)
  const isTrialPro =
    userRoles.includes('ResearchCoordinator') || userRoles.includes('Sponsor')
  const isPatient = userRoles.includes('Patient')

  useEffect(() => {
    setUserRoles(roles)
  }, [])

  return { isPatient, isTrialPro, userRoles }
}
