import { RequestMethod } from '@lib/api/consts'
import internalJsonFetch from '@lib/api/internalJsonFetch'
import ApiRoutes from '@lib/routes/ApiRoutes'

export const getUnreadMessageCount = () => {
  return internalJsonFetch<number>({
    authenticated: true,
    requestMethod: RequestMethod.GET,
    url: ApiRoutes.v1.users.inbox.unreadMessageCount,
  })
}
