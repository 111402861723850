import type { DirectoryLink } from '@components/app/DirectoryLinks'
import {
  DirectoryLinks,
  topTenPsychologyConditionDirectories,
  topTenStateDirectories,
  topTenTreatmentDirectories,
  topTrialByCity,
} from '@components/app/DirectoryLinks'
import { Icon } from '@components/primitives/Icon'
import type { PageWidth } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import { ResponsivePageMargin } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faCity } from '@fortawesome/pro-regular-svg-icons/faCity'
import { faFlagCheckered } from '@fortawesome/pro-regular-svg-icons/faFlagCheckered'
import { faMedkit } from '@fortawesome/pro-regular-svg-icons/faMedkit'
import { faStethoscope } from '@fortawesome/pro-regular-svg-icons/faStethoscope'
import { NavigationLinks } from './NavigationLinks'

export const ExpandedNavbarMenuContent = ({
  isOpen,
  maxWidth = 'max-w-screen-xl',
  setIsOpen,
}: {
  isOpen: boolean
  maxWidth?: PageWidth
  setIsOpen: (isOpen: boolean) => void
}) => {
  const navBlocks: {
    icon: IconDefinition
    links: DirectoryLink[]
    title: string
  }[] = [
    {
      icon: faStethoscope,
      links: topTenPsychologyConditionDirectories,
      title: 'Conditions',
    },
    {
      icon: faMedkit,
      links: topTenTreatmentDirectories,
      title: 'Treatments',
    },
    {
      icon: faCity,
      links: topTrialByCity,
      title: 'Clinical Trials by City',
    },
    {
      icon: faFlagCheckered,
      links: topTenStateDirectories,
      title: 'States',
    },
  ]

  return (
    <div
      className={classMerge(
        'z-10',
        'bg-white',
        'pb-16',
        'md:pb-0',
        'flex',
        'flex-col',
        'md:block',
        'overflow-y-auto',
        'absolute',
        'left-0',
        'w-screen',
        'transition-all',
        'duration-300',
        'md:overflow-hidden',
        'h-full',
        'md:h-auto',
        {
          'hidden opacity-0 md:max-h-0 md:opacity-100': !isOpen,
          'opacity-100 md:max-h-[800px] lg:pt-10': isOpen,
        },
      )}
    >
      {isOpen ? (
        <ResponsivePageMargin contentWidth={maxWidth}>
          <div className='my-3 flex justify-center lg:hidden'>
            <NavigationLinks navExpanded={isOpen} />
          </div>
          <div className='mx-auto grid max-w-screen-2xl grid-cols-1 gap-12 pb-5 lg:grid-cols-4'>
            {navBlocks.map((block, key) => (
              <div className='mb-2' key={key}>
                <Text
                  className='text-neutral500'
                  styleName='p'
                  value={
                    <span>
                      <Icon className='mr-2' icon={block.icon} />
                      {block.title}
                    </span>
                  }
                />
                <div className='mt-2 grid grid-cols-2'>
                  <DirectoryLinks
                    directories={block.links}
                    itemClassName='text-xs lg:text-base my-1'
                    onClickLink={() => setIsOpen(false)}
                  />
                </div>
              </div>
            ))}
          </div>
        </ResponsivePageMargin>
      ) : null}
    </div>
  )
}
