import { classMerge } from '@components/utilities/classMerge'
import type { ReactNode } from 'react'

interface OverlayProps {
  children?: ReactNode
  className?: string
  hidden?: boolean
  onClick: () => void
}

/* Fake invisible overlay to create an out-of-the-box click handler */
export default function ModalOverlay({
  children,
  className,
  hidden,
  onClick,
}: OverlayProps) {
  return (
    <div
      className={classMerge(
        'fixed inset-0 z-modal h-screen w-screen overflow-hidden',
        className,
      )}
      hidden={hidden}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
