import { useEffect, useState } from 'react'

type ScrollDirection = 'up' | 'down' | ''

export type ScrollPosition = {
  direction: ScrollDirection
  x: number
  y: number
}

export default function useRelativeScroll(
  element: HTMLElement | null,
): ScrollPosition {
  const [scrollPosition, setScrollPosition] = useState({
    direction: '' as ScrollDirection,
    x: element?.getBoundingClientRect().left ?? 0,
    y: element?.getBoundingClientRect().top ?? 0,
  })

  useEffect(() => {
    const scrollListener = (_e: Event) => {
      setScrollPosition((prev) => ({
        direction:
          prev.y > -(element?.getBoundingClientRect().top ?? 0)
            ? 'up'
            : ('down' as ScrollDirection),
        x: element?.getBoundingClientRect().left ?? 0,
        y: -(element?.getBoundingClientRect().top ?? 0),
      }))
    }

    window.addEventListener('scroll', scrollListener)

    return () => window.removeEventListener('scroll', scrollListener)
  }, [element])

  return scrollPosition
}
