import { type ScrollPosition } from '@components/hooks/useRelativeScroll'
import { classMerge } from '@components/utilities/classMerge'
import clamp from 'lodash/clamp'
import { useEffect, useRef } from 'react'
import { type NavBarProps } from '.'

export default function TransparentBackground({
  backgroundColor,
  height,
  isMobile,
  open,
  scroll,
  shadow,
  style,
  transitionPoint,
  transparentBackground,
}: Pick<
  NavBarProps,
  'backgroundColor' | 'height' | 'shadow' | 'transparentBackground' | 'style'
> & {
  isMobile: boolean
  open: boolean
  scroll: ScrollPosition
  transitionPoint: number
}) {
  const bgRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (bgRef.current && transparentBackground) {
      bgRef.current.style.opacity = !open
        ? clamp(scroll.y / transitionPoint, 0, 1).toString()
        : '100'
    }
  }, [isMobile, open, scroll.y, transparentBackground])

  return (
    <div
      className={classMerge(
        backgroundColor,
        `fixed inset-x-0 top-0 z-navbarBackground w-screen ${height}`,
        {
          'opacity-0': transparentBackground,
          'opacity-100': open,
          'shadow-lg': shadow,
        },
      )}
      id='navbar-background'
      ref={bgRef}
      style={style}
    />
  )
}
