import decodeJwt from '@modules/auth/jwt/decodeJwt'
import getJwtCookie from '@modules/auth/jwt/getJwtCookie'
import type { UserJwtPayload } from '@modules/auth/jwt/types'
import { useEffect, useState } from 'react'

export function useSignedInUser() {
  const jwt = getJwtCookie()
  const decodedJwt = jwt ? decodeJwt<UserJwtPayload>(jwt) : undefined
  const [signedInUser, setSignedInUser] = useState<UserJwtPayload | undefined>(
    decodedJwt,
  )

  useEffect(() => {
    setSignedInUser(decodedJwt)
  }, [])

  return signedInUser
}
