import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import Routes from '@lib/routes'
import ExternalRoutes from '@lib/routes/ExternalRoutes'

export type ActiveLink = 'sponsor' | 'researcher' | 'about' | 'provider'

export const NavigationLinks = ({
  linkActive,
  navExpanded,
  showForProviders = false,
}: {
  linkActive?: ActiveLink
  navExpanded: boolean
  showForProviders?: boolean
}) => {
  return (
    <div
      className={classMerge(
        'mr-3 flex flex-row items-center justify-between gap-4 md:gap-12',
        {
          // If navbar is open we reduce spacing so there's room for the visible buttons (sign in, ssp, etc.)
          'lg:gap-8': navExpanded,
          'xl:mr-8': navExpanded,
        },
      )}
    >
      <Link href={Routes.aboutUs}>
        <Text
          className={classMerge(
            'whitespace-nowrap text-xs lg:text-base',
            linkActive === 'about' ? 'text-black' : 'text-neutral600',
          )}
          value='About Us'
        />
      </Link>
      {!navExpanded && (
        <Link
          className='flex items-center space-x-2'
          href={ExternalRoutes.careers}
          newTab
        >
          <Text
            className={classMerge(
              'whitespace-nowrap text-xs lg:text-base',
              linkActive === 'about' ? 'text-black' : 'text-neutral600',
            )}
            value='Work With Us'
          />
          <div className='flex size-5 items-center justify-center rounded-full bg-primary500'>
            <Text className='text-white' styleName='p-tiny' value='1' />
          </div>
        </Link>
      )}
      <Link href={Routes.forResearchCoordinators}>
        <Text
          className={classMerge(
            'whitespace-nowrap text-xs lg:text-base',
            linkActive === 'researcher' ? 'text-black' : 'text-neutral600',
          )}
          value='For Researchers'
        />
      </Link>
      <Link href={Routes.forSponsors}>
        <Text
          className={classMerge(
            'whitespace-nowrap text-xs lg:text-base',
            linkActive === 'sponsor' ? 'text-black' : 'text-neutral600',
          )}
          value='For Sponsors'
        />
      </Link>
      {showForProviders && (
        <Link href={Routes.forProviders}>
          <Text
            className={classMerge(
              'whitespace-nowrap text-xs lg:text-base',
              linkActive === 'provider' ? 'text-black' : 'text-neutral600',
            )}
            value='For Providers'
          />
        </Link>
      )}
    </div>
  )
}
