import { getColorConfigFromColorGroup } from '@components/app/Button/helpers/getColorConfigFromColorGroup'
import { Icon } from '@components/primitives/Icon'
import { classMerge } from '@components/utilities/classMerge'
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faMultiply } from '@fortawesome/pro-regular-svg-icons/faMultiply'
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import type { ColorGroup } from '@lib/colors/colors'

export const BurgerButton = ({
  colorGroup,
  onClick,
  open,
}: {
  colorGroup: ColorGroup
  onClick: () => void
  open: boolean
}) => {
  const colorConfig = getColorConfigFromColorGroup(colorGroup)
  const borderClass = `border-${colorConfig.borderColor}`

  return (
    <div
      className={classMerge(
        `rounded-full ${colorConfig.backgroundColor} ${borderClass} flex cursor-pointer items-center justify-center`,
        {
          'border-2': colorConfig.borderColor !== 'transparent',
        },
      )}
      onClick={onClick}
    >
      <div className='flex h-[46px] w-[88px] items-center justify-center'>
        {open ? (
          <Icon className={`${colorConfig.iconColor}`} icon={faMultiply} />
        ) : (
          <>
            <div className='mr-2 flex size-8 items-center justify-center rounded-full bg-primary200/[0.20]'>
              <Icon
                className={`bg-primary200/[0.20] text-primary900`}
                icon={faUser}
                size='sm'
              />
            </div>

            <Icon className={`${colorConfig.iconColor}`} icon={faBars} />
          </>
        )}
      </div>
    </div>
  )
}
