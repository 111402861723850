import { classMerge } from '@components/utilities/classMerge'

export default function UnreadMessagesBubble({
  className,
  count,
}: {
  className?: string
  count?: number
}) {
  if (count === undefined || count < 1) {
    return null
  }
  return (
    <span
      className={classMerge(
        'flex size-5 items-center justify-center rounded-full border border-white bg-hope800 text-xs text-white',
        className,
      )}
    >
      {count > 9 ? '9+' : count}
    </span>
  )
}
