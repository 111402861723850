import { literal } from 'zod'

export enum RoleQueryParam {
  Patient = 'patient',
  Researcher = 'researcher',
  Sponsor = 'sponsor',
}

export const SignInRoleValidator = literal(RoleQueryParam.Patient)
  .or(literal(RoleQueryParam.Researcher))
  .or(literal(RoleQueryParam.Sponsor))
